<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
// golbal
body {
  margin: 0;
  background-color: #29292b;
  color: #fff;
  font-family: "Segoe UI Semibold";
}

a {
  color: #fff;
  text-decoration: none;
}

*::-webkit-scrollbar {
  background-color: rgb(19, 19, 21);
}

*::-webkit-scrollbar-button {
  display: none;
}

.btn {
  padding: 10px;
  color: white;
  background-color: #222;
  font-weight: bold;
  text-align: center;
  border: none;
  box-shadow: 5px 3px 3px #000;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: white;
    border: none;
    background-color: #222;
  }
  &:focus {
    color: white;
    border: none;
    background-color: #222;
  }
}

.btn.large {
  padding: 20px;
}

.btn-g {
  padding: 10px 22px;
  color: black;
  background-color: #1be9a8;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  &:hover {
    color: black;
    border: none;
    background-color: #1be9a8;
  }
  &:focus {
    color: black;
    border: none;
    background-color: #1be9a8;
  }
}

.btn-c {
  padding: 10px;
  color: black;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(135deg, #f05ef8, #29d4ff);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: black;
    border: none;
    background: linear-gradient(135deg, #f05ef8, #29d4ff);
  }
  &:focus {
    color: black;
    border: none;
    background: linear-gradient(135deg, #f05ef8, #29d4ff);
  }
}

.btn-c.large {
  padding: 20px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

@media (min-width: 981px) {
  .mb {
    display: none;
  }
}

@media (max-width: 980px) {
  .pc {
    display: none;
  }
}
</style>

<style lang="less">
// override element ui
.el-row--flex {
  flex-wrap: wrap;
}

.el-dialog {
  width: 40vw;
  background-color: rgb(19, 19, 21) !important;
  border-radius: 5px;
}

.el-dialog__header {
  padding: 35px 35px 10px !important;
}

.el-dialog__body {
  padding: 30px 35px 35px !important;
}

@media (max-width: 980px) {
  .el-dialog {
    width: 90vw;
  }
}

.el-dialog__title {
  color: #fff;
}

.el-dropdown-menu {
  padding: 5px;
  background-color: #1c142c;
  border-image: linear-gradient(90deg, #d922fa, #1be9a8) 1 !important;
  border: 1px solid;
  padding: 5px 20px;
  .el-dropdown-menu__item {
    list-style: none;
    display: block;
    line-height: 36px;
    font-size: 20px;
    color: #afafaf;
    text-decoration: none;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: #4b4b4b;
      color: #afafaf;
    }
  }
}

.el-select-dropdown {
  border: none !important;
  background-color: #1b1c3a !important;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #1a152b !important;
}

.el-select-dropdown__item {
  color: white !important;
}

.popper__arrow {
  border: none !important;
}

.popper__arrow::after {
  border-bottom-color: #1b1c3a !important;
}

.el-table {
  padding: 20px;
  border-radius: 5px;
  background-color: rgb(19, 19, 20);
  color: white;
  thead {
    color: white;
  }
  th,
  tr {
    background-color: transparent;
  }
  .el-table__header {
    background-color: rgb(20, 26, 35);
  }
  td,
  th.is-leaf {
    border-bottom: rgb(27, 28, 33);
  }
  &::before {
    display: none;
  }
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
}

.el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}

.el-table th,
.el-table tr {
  background-color: transparent !important;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
}

.el-table td,
.el-table th.is-leaf {
  border: none !important;
}

@media (max-width: 980px) {
  .el-table th > .cell {
    font-size: 14px;
    font-weight: normal;
  }
}

.el-collapse {
  border: none !important;
}

.el-collapse-item__header {
  background-color: transparent !important;
  border: none !important;
  height: auto !important;
  color: white !important;
}

.el-collapse-item__wrap {
  background-color: transparent !important;
  border: none !important;
}

.el-tabs {
  .el-tabs__item.is-active {
    color: #fff;
  }

  .el-tabs__item {
    font-size: 16px;
    font-weight: bold;
    color: #808080;
    padding: 0 65px 0 10px;

    &:hover {
      color: #fff;
    }
  }

  .el-tabs__active-bar {
    background-image: linear-gradient(to right, #d922fa, #1be9a8);
    height: 3px;
    border-radius: 10px;
  }

  .el-tabs__nav-wrap::after {
    height: 3px;
    background-color: #221636;
  }
}

.el-tabs__active-bar {
  display: none !important;
}

.el-tabs__nav-wrap::after {
  display: none !important;
}

.el-tabs__item:hover {
  color: white !important;
}

.el-tabs__item.is-active {
  color: white !important;
  font-weight: bold !important;
}

.el-tabs__item {
  color: #665e6d !important;
  font-size: 22px !important;
  transition: all 0.4s;
}

@media (max-width: 980px) {
  .el-tabs__item {
    font-weight: normal;
  }
}

.el-notification {
  background-color: #1b152b !important;
  border-image: linear-gradient(to right, #d922fa, #1be9a8) 1 !important;
  border: 1px solid;
  a {
    cursor: pointer;
    color: white;
    text-decoration: none;
  }

  .el-notification__title {
    color: #fff;
  }

  .el-notification__content {
    color: #fff;
  }

  .el-notification__closeBtn {
    color: #fff;
  }
}

.el-message-box {
  background-color: #1b152b !important;
  border-image: linear-gradient(to right, #d922fa, #1be9a8) 1 !important;

  .el-message-box__header {
    .el-message-box__title {
      color: #fff !important;
    }

    .el-message-box__close {
      color: #fff !important;
    }
  }

  .el-message-box__content {
    color: #fff !important;
  }

  .alert_btn_ok {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #fff;
  }
}
</style>
