<template>
  <div class="container">
    <Header />
    <div class="main" :style="{ filter: mainBlur ? 'none' : 'blur(4px)' }">
      <div class="inner">
        <router-view />
      </div>
      <Footer />
    </div>
    <div class="bg"></div>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";

export default {
  name: "Layout",
  computed: {
    mainBlur() {
      if (
        this.$route.path == "/dashboard" ||
        this.$route.path == "/swap" ||
        this.$route.path == "/"
      ) {
        return true;
      } else {
        return this.$store.state.isLogin;
      }
    },
  },
  components: { Header, Footer },
};
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow: hidden;
}

.inner {
  flex: 1;
  width: 70%;
  margin: 0 auto;
  padding: 70px 0;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 980px) {
    width: 95%;
    padding: 10px;
  }
}

.bg {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  background-image: url("../assets/img/bg.jpg");
  background-size: 102% auto;
  background-position: 2% 2%;
  background-repeat: no-repeat;

  @media (max-width: 980px) {
    background-position: 72% 2%;
    background-size: auto 102%;
  }
}
</style>
