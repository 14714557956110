/*
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-09-05 22:30:55
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-09-06 01:03:39
 */
import Wallet from "@project-serum/sol-wallet-adapter";
import { PublicKey, Transaction } from "@solana/web3.js";
import { WalletConnector } from "@sentre/connector";

const poll = (callback, interval, count) => {
  if (count > 0) {
    setTimeout(async () => {
      const done = await callback();
      if (!done) poll(callback, interval, count - 1);
    }, interval);
  }
};

export function pollUntilReady(adapter, pollInterval, pollCount) {
  poll(
    () => {
      const { ready } = adapter;
      if (ready) {
        console.warn(
          `${adapter.constructor.name} is ready but no listener was registered`
        );
      }
      return ready;
    },
    pollInterval,
    pollCount
  );
}

export class SolletWalletAdapter {
  constructor() {
    this._provider = "https://www.sollet.io";
    this._network = "mainnet-beta";
    this._connecting = false;
    this._wallet = null;
  }

  get publicKey() {
    return this._wallet?.publicKey || null;
  }

  get ready() {
    // @FIXME
    return window !== "undefined";
  }

  get connecting() {
    return this._connecting;
  }

  get connected() {
    return !!this._wallet?.connected;
  }

  get autoApprove() {
    return !!this._wallet?.autoApprove;
  }

  async connect() {
    try {
      if (this.connected || this.connecting) return;
      this._connecting = true;
      let wallet;
      let interval;
      try {
        wallet = new Wallet(this._provider, this._network);

        await new Promise((resolve, reject) => {
          wallet.connect().then(resolve, reject);

          if (typeof this._provider === "string") {
            let count = 0;
            interval = setInterval(() => {
              const popup = wallet._popup;
              if (popup) {
                if (popup.closed) reject("WalletWindowClosedError");
              } else {
                if (count > 50) reject("WalletWindowBlockedError");
              }
              count++;
            }, 100);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (interval) clearInterval(interval);
      }
      wallet.on("disconnect", this._disconnected);
      this._wallet = wallet;
    } catch (error) {
      console.log(error);
    } finally {
      this._connecting = false;
    }
  }

  async disconnect() {
    const wallet = this._wallet;
    if (wallet) {
      wallet.off("disconnect", this._disconnected);
      this._wallet = null;
      try {
        await wallet.disconnect();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async signTransaction(transaction) {
    try {
      const wallet = this._wallet;
      if (!wallet) console.log("WalletNotConnectedError");

      try {
        return await wallet.signTransaction(transaction);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async signAllTransactions(transactions) {
    try {
      const wallet = this._wallet;
      if (!wallet) console.log("WalletNotConnectedError");

      try {
        return await wallet.signAllTransactions(transactions);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  _disconnected() {
    const wallet = this._wallet;
    if (wallet) {
      wallet.off("disconnect", this._disconnected);
      this._wallet = null;
    }
  }
}

export class PhantomWalletAdapter {
  constructor() {
    this._connecting = false;
    this._wallet = null;
    this._publicKey = null;

    if (!this.ready) pollUntilReady(this, 1000, 3);
  }

  get publicKey() {
    // return new PublicKey("JzoAUZYzb4oMrtFnPNgXTobx2q5oeKa9Jrw2tP62dqu");
    return this._publicKey;
  }

  get ready() {
    return window !== "undefined" && !!window.solana?.isPhantom;
  }

  get connecting() {
    return this._connecting;
  }

  get connected() {
    return !!this._wallet?.isConnected;
  }

  get autoApprove() {
    return !!this._wallet?.autoApprove;
  }

  async connect() {
    try {
      if (this.connected || this.connecting) return;
      this._connecting = true;

      const wallet = window !== "undefined" && window.solana;
      if (!wallet) console.log("WalletNotFoundError");
      if (!wallet.isPhantom) console.log("WalletNotInstalledError");

      if (!wallet.isConnected) {
        // HACK: Phantom doesn't reject or emit an event if the popup is closed
        const disconnect = wallet._handleDisconnect;
        try {
          await new Promise((resolve, reject) => {
            const connect = () => {
              wallet.off("connect", connect);
              resolve();
            };

            wallet._handleDisconnect = (...args) => {
              wallet.off("connect", connect);
              reject("WalletWindowClosedError");
              return disconnect.apply(wallet, args);
            };

            wallet.on("connect", connect);

            wallet.connect().catch((reason) => {
              wallet.off("connect", connect);
              reject(reason);
            });
          });
        } catch (error) {
          console.log(error);
        } finally {
          wallet._handleDisconnect = disconnect;
        }
      }

      let bytes;
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        bytes = wallet.publicKey.toBytes();
      } catch (error) {
        console.log(error);
      }

      let publicKey;
      try {
        publicKey = new PublicKey(bytes);
      } catch (error) {
        console.log(error);
      }

      wallet.on("disconnect", this._disconnected);

      this._wallet = wallet;
      this._publicKey = publicKey;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      this._connecting = false;
    }
  }

  async disconnect() {
    const wallet = this._wallet;
    if (wallet) {
      wallet.off("disconnect", this._disconnected);

      this._wallet = null;
      this._publicKey = null;

      try {
        await wallet.disconnect();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async signTransaction(transaction) {
    try {
      const wallet = this._wallet;
      if (!wallet) console.log("WalletNotConnectedError");

      try {
        return await wallet.signTransaction(transaction);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async signAllTransactions(transactions) {
    try {
      const wallet = this._wallet;
      if (!wallet) console.log("WalletNotConnectedError");

      try {
        return await wallet.signAllTransactions(transactions);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async signMessage(message) {
    try {
      const wallet = this._wallet;
      if (!wallet) console.log("WalletNotConnectedError");

      try {
        const { signature } = await wallet.signMessage(message);
        return Uint8Array.from(signature);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  _disconnected() {
    const wallet = this._wallet;
    if (wallet) {
      wallet.off("disconnect", this._disconnected);
      this._wallet = null;
      this._publicKey = null;
      console.log("WalletDisconnectedError");
    }
  }
}

export class SentreWalletAdapter {
  constructor() {
    this._connecting = false;
    this._connected = false;
    this._wallet = null;
    this._ready = true;
    this._publicKey = null;
    this._autoApprove = false;
  }

  get publicKey() {
    return this._publicKey;
  }

  get ready() {
    return this._ready;
  }

  get connecting() {
    return this._connecting;
  }

  get connected() {
    return this._connected;
  }

  get autoApprove() {
    return this.autoApprove;
  }

  async connect() {
    if (this._connected || this._connecting) return;
    this._connecting = true;
    try {
      const wallet = new WalletConnector("sypool");
      this._ready = true;
      if (!wallet) console.log("WalletNotFoundError");
      this._connected = await wallet.isConnected();
      if (this._connected) {
        let address = await wallet.getAddress();
        console.log("sentre address", address);
        this._publicKey = new PublicKey(address);
        this._wallet = wallet;
      }
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      this._connecting = false;
    }
  }

  async disconnect() {
    console.warn("sentre connector has no disconnect function.");
  }

  async signTransaction(transaction) {
    try {
      const wallet = this._wallet;
      if (!wallet) console.log("WalletNotConnectedError");

      try {
        return await wallet.signTransaction(transaction);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async signAllTransactions() {
    console.warn("sentre connector has no sign all transaction function.");
  }

  async signMessage() {
    console.warn("sentre connector has no sign message function.");
  }
}
