<template>
  <el-dialog :visible="visible">
    <template slot="title">
      <div class="title">Welcome to Sypool Protocol</div>
    </template>
    <div class="text">
      <ul>
        <li>
          Sypool Protocol is a decentralized synthetic asset management
          protocol, providing financial services.
        </li>
        <li>
          If you encounter any bugs during your use, please report them in the
          bug-report channel on
          <a
            href="http://discord.gg/UmCUEjXrhv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>
          .
        </li>
        <li>
          The protocol is in
          <b>BETA</b>
          version, use at your own risk.
        </li>
      </ul>
    </div>
    <el-checkbox class="check-box" v-model="confirm">
      I have read and agreed to
      <a
        href="https://sypool-protocol.gitbook.io/welcome-to-sypool/disclaimer"
        target="_blank"
        rel="noopener noreferrer"
      >
        Disclaimer
      </a>
      .
    </el-checkbox>
    <div class="btn-box">
      <div class="btn-c" :class="{ disabled: !confirm }" @click="launch">
        Launch App
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "DisclaimerDialog",
  data() {
    return {
      visible: false,
      confirm: false,
    };
  },
  methods: {
    goToGensis() {
      if (this.confirm) {
        window.open("https://app.sypool.io", "_blank");
      }
    },
    launch() {
      let confirmData = {
        confirm: this.confirm,
        time: new Date(),
      };
      sessionStorage.setItem("confirm", JSON.stringify(confirmData));
      this.visible = false;
      this.confirm = false;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 20px 10px 10px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.text {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid #42346b 2px;
  color: white;
  word-break: normal;
  ul {
    padding-left: 1em;
    li {
      font-size: 16px;
    }
  }
  b {
    font-weight: 700;
  }
}
.check-box {
  margin: 20px auto;
  color: white;
  > .el-checkbox__label {
    font-size: 16px !important;
  }
  &.is-checked > .el-checkbox__label {
    color: white !important;
  }
}
.btn-box {
  margin-top: 15px;
  text-align: center;
  .btn-c {
    margin: 0 auto;
    width: 20%;
  }
}
a {
  color: white;
  &:visited {
    color: white;
  }
}
</style>
