import Vue from "vue";
import VueRouter from "vue-router";
import BaseLayout from "../layouts/base.vue";
import ScrollLayout from "../layouts/scroll.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: BaseLayout,
    redirect: "/",
    children: [
      {
        path: "/",
        redirect: "/dashboard",
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/dashboard.vue"),
      },
      {
        path: "/exploremain",
        name: "Exploremain",
        component: () => import("../views/exploremain.vue"),
      },
      {
        path: "/investment",
        name: "Investment",
        component: () => import("../views/investment.vue"),
      },
      {
        path: "/swap",
        name: "Swap",
        component: () => import("../views/swap0xGen.vue"),
      },
      {
        path: "/staking",
        name: "Staking",
        component: () => import("../views/staking.vue"),
      },
      {
        path: "/stakingold",
        name: "StakingOld",
        component: () => import("../views/stakingOld.vue"),
      },
      {
        path: "/reward",
        name: "Reward",
        component: () => import("../views/reward.vue"),
      },
      {
        path: "/ai-indicators",
        name: "AiIndicators",
        component: () => import("../views/aiIndicators.vue"),
      },
    ],
  },
  {
    path: "/sypool",
    component: ScrollLayout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "Sypool",
        component: () => import("../views/sypool.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
