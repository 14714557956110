import { sleep } from "./timeout";

export async function signAndSendTransaction(
  connection,
  wallet,
  partialSignerList,
  transaction
) {
  // use account
  let walletAcc = wallet.publicKey;
  // make transaction
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  if (partialSignerList) {
    // signer has screty key and public key
    for (let i = 0; i < partialSignerList.length; i++) {
      let signer = partialSignerList[i];
      transaction.partialSign(signer);
    }
  }
  // sign
  let signed;
  try {
    signed = await wallet.signTransaction(transaction);
  } catch (error) {
    return { code: -1, msg: "sign canceled", error };
  }
  // send transaction
  let res = await sendTransaction(connection, signed.serialize());
  if (res.code == 1) {
    return res;
  } else if (res.code == 0) {
    return res;
  } else {
    return { code: 0, msg: "unkown error" };
  }
}

export async function sendTransaction(connection, transaction) {
  try {
    let signatrue = await connection.sendRawTransaction(transaction);
    let transactionStatus = await getSignatureStatus(connection, signatrue);
    if (transactionStatus) {
      return { code: 1, msg: "send transaction ok", data: signatrue };
    } else {
      return {
        code: 1,
        msg: "can not confirm transaction status please check on explore",
        data: signatrue,
        signatrue,
      };
    }
  } catch (error) {
    console.error("send transaction error", error);
    return {
      code: 0,
      msg: "send transaction error",
      data: error,
      signatrue: "",
    };
  }
}

export async function getSignatureStatus(connection, signatrue) {
  let temp = { value: null };
  let flag = true;
  let startDate = new Date();
  while (flag) {
    await sleep(3000);
    temp = await connection.getSignatureStatus(signatrue);
    if (temp.value) {
      let nowDate = new Date();
      let passTime = nowDate.getTime() - startDate.getTime();
      console.log("transaction", temp.value.confirmationStatus, passTime, "ms");
      if (temp.value.confirmationStatus == "finalized") {
        flag = false;
        return temp.value;
      }
    }
  }
}
