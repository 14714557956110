<template>
  <div class="header">
    <a href="https://sypool.io/">
      <img
        class="logo-img"
        src="../../assets/img/logo.png"
        alt="sypool"
        @click="navVisible = !navVisible"
      />
    </a>
    <div v-if="walletHash" class="pc-btn btn-g" @click="ConnectShow">
      {{ walletHashShort }}
    </div>
    <div v-if="!walletHash" class="pc-btn btn-g" @click="ConnectShow">
      Connect Wallet
    </div>

    <div class="nav-container" :class="{ visible: navVisible }">
      <router-link to="/dashboard" class="nav-item">
        <img src="../../assets/img/nav_pools.png" />
        <span>Dashboard</span>
        <div class="nav-at-border"></div>
      </router-link>
      <router-link to="/exploremain" class="nav-item">
        <img src="../../assets/img/dashboard_nav.png" />
        <span>Pools</span>
        <div class="nav-at-border"></div>
      </router-link>
      <router-link to="/staking" class="nav-item">
        <img src="../../assets/img/staking_nav.png" />
        <span>Earn</span>
        <div class="nav-at-border"></div>
      </router-link>
      <router-link to="/investment" class="nav-item" v-if="walletHash">
        <img :src="userphoto" />
        <span>Investment</span>
        <div class="nav-at-border"></div>
      </router-link>
      <div
        class="nav-item"
        @click="connectDialogVisible = true"
        v-if="!walletHash"
      >
        <img src="../../assets/img/nav_investment.png" />
        <span>Investment</span>
        <div class="nav-at-border"></div>
      </div>
      <router-link to="/swap" class="nav-item">
        <img src="../../assets/img/swap.png" />
        <span>Swap</span>
        <div class="nav-at-border"></div>
      </router-link>
      <router-link to="/ai-indicators" class="nav-item">
        <img src="../../assets/img/swap.png" />
        <span>AI Indicators</span>
        <div class="nav-at-border"></div>
      </router-link>
      <div class="visible-btn" @click="navVisible = !navVisible">
        <i v-show="navVisible" class="el-icon-arrow-left"></i>
        <i v-show="!navVisible" class="el-icon-arrow-right"></i>
      </div>
    </div>

    <el-dropdown trigger="click" class="nav-mobile">
      <i class="icon el-icon-menu"></i>
      <el-dropdown-menu slot="dropdown" style="width: 80vw">
        <el-dropdown-item>
          <el-button v-if="walletHash" class="btn-g" @click="ConnectShow">
            {{ walletHashShort }}
          </el-button>
          <el-button v-if="!walletHash" class="btn-g" @click="ConnectShow">
            Connect Wallet
          </el-button>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link to="/dashboard">Dashboard</router-link>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link to="/exploremain">Pools</router-link>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link to="/staking">Earn</router-link>
        </el-dropdown-item>
        <el-dropdown-item v-show="!walletHash">
          <a @click="connectDialogVisible = true">Investment</a>
        </el-dropdown-item>
        <el-dropdown-item v-show="walletHash">
          <router-link to="/investment">Investment</router-link>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link to="/swap">Swap</router-link>
        </el-dropdown-item>
        <el-dropdown-item>
          <router-link to="/ai-indicators">AI Indicators</router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- connect wallet dialog -->
    <el-dialog
      :visible.sync="connectDialogVisible"
      :modal="true"
      title="Connect Wallet"
      top="3vh"
    >
      <ul class="wallet-list">
        <li
          v-for="item in accountList"
          :key="item.name"
          class="wallet-item"
          @click="connectWallet(item.name)"
        >
          <div class="wallet-icon">
            <img :src="item.img" alt />
          </div>
          <span class="wallet-name">{{ item.name }}</span>
          <span class="wallet-desc pc">{{ item.describe }}</span>
        </li>
      </ul>
    </el-dialog>
    <!-- wallet dialog -->
    <el-dialog
      :visible.sync="walletDialogVisible"
      :modal="true"
      title="Your wallet"
      class="wallet-dialog"
    >
      <div class="wallet-content">
        <div class="hash">{{ walletHash }}</div>
        <el-button type="primary" class="innerbtn" @click="logout">
          DISCONNECT
        </el-button>
        <div class="rt">
          <div class="tittle1">Recent Transactions</div>
          <div class="rtinner" v-if="transactionHistoryList[0]">
            <span class="rtcolor">
              {{
                transactionHistoryList[0].actionStr +
                " " +
                transactionHistoryList[0].amount +
                " " +
                transactionHistoryList[0].sapName
              }}
            </span>
            <span>{{ transactionHistoryList[0].date }}</span>
          </div>
          <div class="rtinner" v-if="transactionHistoryList[1]">
            <span class="rtcolor">
              {{
                transactionHistoryList[1].actionStr +
                " " +
                transactionHistoryList[1].amount +
                " " +
                transactionHistoryList[1].sapName
              }}
            </span>
            <span>{{ transactionHistoryList[1].date }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <DisclaimerDialog ref="disclaimerDialog" />
  </div>
</template>

<script>
import solletIcon from "../../assets/img/sollet.png";
import solflareIcon from "../../assets/img/solflare.png";
import mathwalletIcon from "../../assets/img/mathwallet.png";
import phantomIcon from "../../assets/img/phantom.png";
import coin98Icon from "../../assets/img/coin98.png";
import ledgerIcon from "../../assets/img/ledger.png";
import sentreIcon from "../../assets/img/sentre.png";
import { getTransactionList } from "../../assets/js/sap/sap";
import { Connection } from "@solana/web3.js";
import {
  SolletWalletAdapter,
  PhantomWalletAdapter,
  SentreWalletAdapter,
} from "../../utils/index";
import { SY_LEN } from "@/assets/js/sap/sap";
import { rpcUrl } from "@/assets/js/index";
import {
  SolflareWalletAdapter,
  Coin98WalletAdapter,
  MathWalletAdapter,
  LedgerWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import DisclaimerDialog from "../../components/disclaimerDialog.vue";
import { rpcConfig } from "../../assets/js";

const colors = ["#8473C6", "#DBBB9A", "#84BED4", "#6F99D2", "#9EDB90"];

export default {
  name: "Header",
  data() {
    return {
      reminder: 0,
      connectDialogVisible: false,
      walletHash: "",
      walletDialogVisible: false,
      userphoto: require("../../assets/img/user0.png"),
      connection: new Connection(rpcUrl, rpcConfig),
      transactionHistoryList: [],
      fundList: [],
      accountList: [
        {
          img: phantomIcon,
          name: "Phantom",
          describe: "Connect with your Phantom account",
        },
        {
          img: solletIcon,
          name: "Sollet",
          describe: "Connect with your Sollet account",
        },
        {
          img: solflareIcon,
          name: "Solflare",
          describe: "Connect with your Solflare account",
        },
        {
          img: coin98Icon,
          name: "Coin98",
          describe: "Connect with your coin98 account",
        },
        {
          img: mathwalletIcon,
          name: "Mathwallet",
          describe: "Connect with your mathwallet account",
        },
        {
          img: ledgerIcon,
          name: "Ledger",
          describe: "Connect with your Ledger account",
        },
        {
          img: sentreIcon,
          name: "Sentre",
          describe: "Connect with your Sentre account",
        },
      ],
      wallet: null,
      navVisible: true,
    };
  },
  mounted() {
    this.disclaimer();
    // this.generUserPhoto()
    console.log(this.$route);
  },
  methods: {
    ConnectShow() {
      if (this.walletHash) {
        this.walletDialogVisible = true;
      } else {
        this.connectDialogVisible = true;
      }
    },
    openWindow() {
      window.open(
        "https://sypool.io/wp-content/uploads/2021/07/Sypool-white-paper.pdf"
      );
    },
    goToDash() {
      this.$router.push("/ranking");
    },
    goToReward() {
      this.$router.push("/reward");
    },
    gotoBottom() {
      document
        .getElementById("footer")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    goToBlog() {
      window.open("https://medium.com/@SYP_Protocol", "_blank");
    },
    goToLink(url) {
      this.$router.push(url);
    },
    async connectWallet(name) {
      if (name === "Phantom") {
        // phantom
        try {
          this.wallet = new PhantomWalletAdapter();
          await this.wallet.connect();
          console.log("phantom wallet connect", this.wallet);
          this.connectDialogVisible = false;
          this.$store.state.isLogin = true;
          this.$store.state.walletConnectInfo = {
            wallet: this.wallet,
            connection: this.connection,
            walleturl: "https://wwww.phantom.app",
            publicKey: this.wallet.publicKey,
          };
          this.walletHash = this.wallet.publicKey.toBase58();
        } catch (error) {
          console.error("connect phantom wallet error", error);
        }
      } else if (name === "Sollet") {
        // sollet
        try {
          this.wallet = new SolletWalletAdapter();
          await this.wallet.connect();
          console.log("sollet wallet connect", this.wallet);
          this.connectDialogVisible = false;
          this.$store.state.isLogin = true;
          this.$store.state.walletConnectInfo = {
            wallet: this.wallet,
            connection: this.connection,
            walleturl: "https://www.sollet.io",
            publicKey: this.wallet.publicKey,
          };
          this.walletHash = this.wallet.publicKey.toBase58();
        } catch (error) {
          console.error("connect sollet wallet error", error);
        }
      } else if (name === "Solflare") {
        // solflare
        try {
          this.wallet = new SolflareWalletAdapter();
          await this.wallet.connect();
          console.log("solflare wallet connect", this.wallet);
          this.connectDialogVisible = false;
          this.$store.state.isLogin = true;
          this.$store.state.walletConnectInfo = {
            wallet: this.wallet,
            connection: this.connection,
            walleturl: "https://www.sollet.io",
            publicKey: this.wallet.publicKey,
          };
          this.walletHash = this.wallet.publicKey.toBase58();
        } catch (error) {
          console.error("connect solflare wallet error", error);
        }
      } else if (name === "Coin98") {
        // coin98
        try {
          this.wallet = new Coin98WalletAdapter();
          await this.wallet.connect();
          console.log("coin98 wallet connect", this.wallet);
          this.connectDialogVisible = false;
          this.$store.state.isLogin = true;
          this.$store.state.walletConnectInfo = {
            wallet: this.wallet,
            connection: this.connection,
            walleturl: "https://www.sollet.io",
            publicKey: this.wallet.publicKey,
          };
          this.walletHash = this.wallet.publicKey.toBase58();
        } catch (error) {
          console.error("connect coin98 wallet error", error);
        }
      } else if (name === "Mathwallet") {
        // mathwallet
        try {
          this.wallet = new MathWalletAdapter();
          await this.wallet.connect();
          console.log("mathwallet connect", this.wallet);
          this.connectDialogVisible = false;
          this.$store.state.isLogin = true;
          this.$store.state.walletConnectInfo = {
            wallet: this.wallet,
            connection: this.connection,
            walleturl: "https://www.sollet.io",
            publicKey: this.wallet.publicKey,
          };
          this.walletHash = this.wallet.publicKey.toBase58();
        } catch (error) {
          console.error("connect mathwallet error", error);
        }
      } else if (name === "Ledger") {
        // ledger
        try {
          this.wallet = new LedgerWalletAdapter();
          await this.wallet.connect();
          console.log("ledger wallet connect", this.wallet);
          this.connectDialogVisible = false;
          this.$store.state.isLogin = true;
          this.$store.state.walletConnectInfo = {
            wallet: this.wallet,
            connection: this.connection,
            walleturl: "https://www.sollet.io",
            publicKey: this.wallet.publicKey,
          };
          this.walletHash = this.wallet.publicKey.toBase58();
        } catch (error) {
          console.error("connect ledger wallet error", error);
        }
      } else if (name === "Sentre") {
        // sentre
        try {
          this.wallet = new SentreWalletAdapter();
          await this.wallet.connect();
          console.log("sentre wallet connect", this.wallet);
          this.connectDialogVisible = false;
          this.$store.state.isLogin = true;
          this.$store.state.walletConnectInfo = {
            wallet: this.wallet,
            connection: this.connection,
            walleturl: "https://www.sollet.io",
            publicKey: this.wallet.publicKey,
          };
          this.walletHash = this.wallet.publicKey.toBase58();
        } catch (error) {
          console.error("connect sentre wallet error", error);
        }
      } else {
        console.log("unknown wallet");
      }
      if (this.$store.state.isLogin == true) {
        let result = await this.$axios.post("/getFundList", { net: 0 });
        let products = result.data.data;
        let tempFundList = [];
        for (var i = 0; i < products.length; i++) {
          if (products[i].type == 0) {
            tempFundList.push({
              name: products[i].name,
              mint: products[i].mint[0],
              pubkey: products[i].pubkey,
              type: 0,
            });
          } else if (products[i].type == 1) {
            for (let j = 1; j < 1 + SY_LEN; j++) {
              tempFundList.push({
                name: products[i].name + "-R" + j.toString(),
                mint: products[i].mint[j - 1],
                pubkey: products[i].pubkey,
                type: 1,
                ltype: j,
              });
            }
          }
        }
        this.fundList = tempFundList;
        let res = await getTransactionList(
          this.connection,
          this.walletHash,
          ""
        );
        if (res.length > 0) {
          this.transactionHistoryList = res.filter((e) => {
            return e.action > 0;
          });
          if (this.transactionHistoryList.length > 0) {
            let fundPubkeyList = this.fundList.map((e) => {
              return e.pubkey;
            });
            for (let i = 0; i < this.transactionHistoryList.length; i++) {
              let pubkey = this.transactionHistoryList[i].pubkey;
              let index = fundPubkeyList.indexOf(pubkey);
              if (index != -1) {
                this.$set(
                  this.transactionHistoryList[i],
                  "sapName",
                  this.fundList[index].name
                );
              }
            }
          }
        }
        let pubkey = this.wallet.publicKey.toBase58();
        let reminder = this.getUserPhoto(pubkey) % 6;
        this.userphoto = require("../../assets/img/user" + reminder + ".png");
      }
    },

    getUserPhoto(pubkeyStr) {
      let count = 0;
      if (!pubkeyStr) return;
      const valMap = {
        a: 1,
        b: 2,
        c: 3,
        d: 4,
        e: 5,
        f: 6,
        g: 7,
        h: 8,
        i: 9,
        j: 10,
        k: 11,
        l: 12,
        m: 13,
        n: 14,
        o: 15,
        p: 16,
        q: 17,
        r: 18,
        s: 19,
        t: 20,
        u: 21,
        v: 22,
        w: 23,
        x: 24,
        y: 25,
        z: 26,
      };
      const pubkeyAry = pubkeyStr.toLowerCase().split("");
      pubkeyAry.forEach((item) => {
        const curVal = parseInt(item);
        if (!isNaN(curVal)) {
          count += parseInt(item);
        } else {
          count += valMap[item];
        }
      });
      return count % 6;
    },
    getImageUrl() {
      console.log(this.userphoto);
      return this.userphoto;
    },
    generUserPhoto() {
      var data = [];
      for (var i = 0; i < 5; i++) {
        data.push([
          Math.floor(Math.random() * 2),
          Math.floor(Math.random() * 2),
          Math.floor(Math.random() * 2),
        ]);
      }
      data = data.map(function (d) {
        d.push(d[1], d[0]);
        return d;
      });
      // duplicate first two rows
      identicon.innerHTML = "";
      var color = colors[Math.floor(Math.random() * colors.length)];
      data.forEach(function (d) {
        d.forEach(function (_d) {
          var el = document.createElement("div");
          el.setAttribute("data", _d);
          el.style.background = color;
          identicon.appendChild(el);
        });
      });
    },
    logout() {
      location.reload();
    },
    disclaimer() {
      let confirm = sessionStorage.getItem("confirm");
      if (confirm) {
        let confirmData = JSON.parse(confirm);
        if (confirmData.confirm) {
          return;
        }
      }
      this.$refs.disclaimerDialog.visible = true;
    },
  },
  computed: {
    walletHashShort() {
      return (
        this.walletHash.slice(0, 5) +
        "..." +
        this.walletHash.slice(
          this.walletHash.length - 4,
          this.walletHash.length
        )
      );
    },
  },
  components: {
    DisclaimerDialog,
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 30px;
}

.logo-img {
  height: 44px;
  cursor: pointer;
}

@media (max-width: 980px) {
  .header {
    padding: 0 15px;
    height: 50px;
  }
  .pc-btn {
    display: none;
  }
}

.nav-container {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
  background-color: #404040;
  transition: all 0.4s;
  border-radius: 0 5px 5px 0;
  padding: 10px 0;

  .visible-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateX(100%) translateY(-50%);
    padding: 30px 5px;
    cursor: pointer;
    background-color: #333;
  }

  &.visible {
    transform: translateY(-50%);
  }

  .nav-item {
    height: 80px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-size: 12px;
    line-height: 22px;
    & > img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    /deep/ &.router-link-active {
      .nav-at-border {
        display: block;
      }
    }
  }

  .nav-at-border {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 3px;
    background-image: linear-gradient(to right, #d922fa, #1be9a8);
  }
}

@media (max-width: 980px) {
  .nav-container {
    display: none;
  }
}

.nav-mobile {
  display: none;

  .icon {
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
}

@media (max-width: 980px) {
  .nav-mobile {
    display: inline-block;
  }
}

.wallet-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;

  .wallet-item {
    display: flex;
    align-items: center;

    color: #fff;
    font-size: 16px;

    background-color: #404040;

    padding: 25px 40px;
    border-bottom: 2px solid #646464;
    cursor: pointer;

    .wallet-desc {
      word-break: break-word;
    }

    &:last-child {
      border: none;
    }

    .wallet-icon {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      @media (max-width: 980px) {
        height: 35px;
        width: 35px;
      }
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .wallet-name {
      display: inline-block;
      width: 150px;
      font-size: 18px;
      margin-right: 10px;
      @media (max-width: 980px) {
        font-size: 15px;
      }
    }
  }
}

.wallet-content {
  width: 100%;
  border-top: 2px solid;
  border-image: linear-gradient(to right, #d922fa, #1be9a8) 1 !important;
  display: grid;
  .hash {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: white;
    margin: 20px 0;
  }
  .innerbtn {
    margin: auto;
    background: transparent;
    border: 2px solid turquoise;
  }
  .rt {
    width: 100%;
    margin: 40px 20px 0px;
    .rtinner {
      span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        color: #a8abb9;
        width: 45%;
        margin-right: 10px;
      }
      .rtcolor {
        color: #46949c;
      }
    }
  }
  .tittle1 {
    font-size: 22px;
    color: white;
    margin-bottom: 15px;
  }
}

@media (max-width: 980px) {
  .wallet-content {
    .hash {
      font-size: 14px !important;
      font-weight: initial;
    }

    .rt {
      .rtinner {
        span {
          margin-right: 2px !important;
          font-size: 9px !important;
        }
      }
    }
  }
}
</style>
