import { PublicKey } from "@solana/web3.js";

export const SYPMINT = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
export const USDCMINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";

const tokenList1 = require("./tokenList.json");
let tokenList2 = tokenList1.filter((e) => {
  return e.chainId === 101;
});
let tokenList3 = tokenList2.map((e) => {
  return { publicKey: e.address, name: e.symbol };
});

// USDC must be the first token
export const tokenList = [{ publicKey: USDCMINT, name: "USDC" }, ...tokenList3];

export function getTokenPublicKey(tokenName) {
  let tokenTemp = tokenList.find((e) => {
    return e.name == tokenName.toUpperCase();
  });
  if (tokenTemp) {
    return tokenTemp.publicKey;
  } else {
    console.error("can not find token", tokenName);
    return;
  }
}

export function getTokenName(mintKey) {
  let tokenTemp = tokenList.find((e) => {
    return e.publicKey == mintKey;
  });
  if (tokenTemp) {
    return tokenTemp.name;
  } else {
    return mintKey.slice(0, 3);
  }
}

// {
//   "chainId": 101,
//   "address": "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
//   "symbol": "BTC",
//   "name": "Wrapped Bitcoin (Sollet)",
//   "decimals": 6,
//   "logoURI": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E/logo.png",
//   "tags": ["wrapped-sollet", "ethereum"],
//   "extensions": {
//     "bridgeContract": "https://etherscan.io/address/0xeae57ce9cc1984f202e15e038b964bb8bdf7229a",
//     "coingeckoId": "bitcoin",
//     "serumV3Usdc": "A8YFbxQYFVqKZaoYJLLUVcQiWP7G2MeEgW5wsAQgMvFw",
//     "serumV3Usdt": "C1EuT9VokAKLiW7i2ASnZUvxDoKuKkCpDDeNxAptuNe4"
//   }
// },
export function getTokenInfo(tokenName) {
  return tokenList2.find((e) => {
    return e.symbol === tokenName;
  });
}

export const TOKENS = [
  {
    pair: "USDC/USD",
    pubkey: USDCMINT,
    amount: 0,
    price: 1,
    token: "USDC",
    decimals: 9,
    userAcc: "",
    oracle: "Gnt27xtC473ZT2Mw5u8wZ68Z3gULkSTb5DuxJy7eJotD",
  },
  // {"pair":"SOL/USD",'pubkey':'6b5m68a99Lx48BFkBMf7DKbuSkFsnTczhRKKtotVny5R','amount':0,'price':1,'token':'SOL','decimals':9,'userAcc':'','oracle':"3Mnn2fX6rQyUsyELYms1sBJyChWofzSNRoqYzvgMVz5E"},
  // {"pair":"BTC/USD",'pubkey':"EVnGvMn8eWLF19NJTBqtChnVfyfP3JqgvpnbTToC1UvW",'amount':0,'price':1,'token':'BTC','decimals':9,'userAcc':'','oracle':"3m1y5h2uv7EQL3KaJZehvAJa4yDNvgc5yAdL9KPMKwvk"},
  // {"pair":"ETH/USD","pubkey":"fJwPqBDakgR4V3RTfh87xFbhX8V4AgNLvFmpW4iYtbv",'amount':0,'price':1,'token':'ETH','decimals':9,'userAcc':'','oracle':"2ciUuGZiee5macAMeQ7bHGTJtwcYTgnt6jdmQnnKZrfu"},
  {
    pair: "SYP/USD",
    pubkey: SYPMINT,
    amount: 0,
    price: 0.05,
    token: "SYP",
    decimals: 9,
    userAcc: "",
    oracle: "11111111111111111111111111111111",
  },
];

export const LPMINT = "9idQao7pof65PyVUyDGsrjmmVLwZu9S9V2M4SRjMwPvd";

export async function getMintData(connection, mintKey) {
  let res = await connection.getParsedAccountInfo(new PublicKey(mintKey));
  if (res.value) {
    // decimals freezeAuthority isInitialized mintAuthority supply
    return {
      code: 1,
      msg: "get mint data ok",
      data: res.value.data.parsed.info,
    };
  } else {
    return { code: 0, msg: "get mint data error" };
  }
}
