import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import * as BufferLayout from "buffer-layout";
import BN from "bn.js";

// mainnet
const ChainLinkFeedProgramId = "cjg3oHmg9uuPsP8D6g29NWvhySJkdYdAo9D25PRbKXJ";
const chainlink_program_acc = new PublicKey(ChainLinkFeedProgramId);

export class SapInstruction {
  static createBuyInstruction(
    sap_owner_key,
    token_program_key,
    sap_pool_acc,
    sap_pool_mint_acc,
    user_sap_acc,
    user_wallet_acc,
    user_token_acc,
    token_list_key,
    token_vault_key,
    oracle_key,
    token_pubkey,
    amount_in,
    mint_fee,
    programId
  ) {
    console.log(
      "sap buy",
      "sap owners",
      sap_owner_key.toBase58(),
      "token program id",
      token_program_key.toBase58(),
      "sap pool",
      sap_pool_acc.toBase58(),
      "sap pool mint",
      sap_pool_mint_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user wllet",
      user_wallet_acc.toBase58(),
      "user token",
      user_token_acc.toBase58(),
      "chainlink program",
      chainlink_program_acc.toBase58(),
      "token",
      token_pubkey.toBase58(),
      "amount",
      amount_in,
      "mint fee",
      mint_fee,
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(32, "token_pubkey"),
      BufferLayout.nu64("amount_in"),
      BufferLayout.nu64("fee"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 1,
        token_pubkey: Buffer.from(token_pubkey.toBuffer()),
        amount_in,
        fee: mint_fee,
      },
      data
    );

    let keys = [
      { pubkey: sap_owner_key, isSigner: true, isWritable: false },
      { pubkey: token_program_key, isSigner: false, isWritable: false },
      { pubkey: sap_pool_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: user_sap_acc, isSigner: false, isWritable: true },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_token_acc, isSigner: false, isWritable: true },
      { pubkey: chainlink_program_acc, isSigner: false, isWritable: false },
    ];
    let i = 0;
    while (i < token_list_key.length) {
      console.log(
        "token",
        token_list_key[i],
        "vault",
        token_vault_key[i],
        "oracle",
        oracle_key[i]
      );
      keys.push({
        pubkey: new PublicKey(token_list_key[i]),
        isSigner: false,
        isWritable: false,
      });
      keys.push({
        pubkey: new PublicKey(token_vault_key[i]),
        isSigner: false,
        isWritable: true,
      });
      keys.push({
        pubkey: new PublicKey(oracle_key[i]),
        isSigner: false,
        isWritable: false,
      });
      i++;
    }

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static createRedeemInstruction(
    sap_owner_key,
    token_program_key,
    sap_pool_acc,
    sap_pool_mint_acc,
    user_sap_acc,
    user_wallet_acc,
    user_token_acc,
    manager_acc,
    token_list_key,
    token_vault_key,
    oracle_key,
    token_pubkey,
    amount_in,
    burn_fee,
    sap_cost,
    performance_fee,
    programId
  ) {
    console.log(
      "sap redeem",
      "sap owners",
      sap_owner_key.toBase58(),
      "token program id",
      token_program_key.toBase58(),
      "sap pool",
      sap_pool_acc.toBase58(),
      "sap pool mint",
      sap_pool_mint_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user wllet",
      user_wallet_acc.toBase58(),
      "user token",
      user_token_acc.toBase58(),
      "manager",
      manager_acc.toBase58(),
      "token",
      token_pubkey.toBase58(),
      "amount",
      amount_in,
      "burn fee",
      burn_fee,
      "sap cost",
      sap_cost,
      "performance fee",
      performance_fee,
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(32, "token_pubkey"),
      BufferLayout.blob(8, "amount_in"),
      BufferLayout.blob(8, "burn_fee"),
      BufferLayout.blob(8, "sap_cost"),
      BufferLayout.blob(8, "performance_fee"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 2,
        token_pubkey: Buffer.from(token_pubkey.toBuffer()),
        amount_in: Buffer.from(new BN(amount_in).toArray("le", 8)),
        burn_fee: Buffer.from(new BN(burn_fee).toArray("le", 8)),
        sap_cost: Buffer.from(new BN(sap_cost).toArray("le", 8)),
        performance_fee: Buffer.from(new BN(performance_fee).toArray("le", 8)),
      },
      data
    );

    let keys = [
      { pubkey: sap_owner_key, isSigner: false, isWritable: false },
      { pubkey: token_program_key, isSigner: false, isWritable: false },
      { pubkey: sap_pool_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: user_sap_acc, isSigner: false, isWritable: true },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_token_acc, isSigner: false, isWritable: true },
      { pubkey: manager_acc, isSigner: true, isWritable: false },
      { pubkey: chainlink_program_acc, isSigner: false, isWritable: false },
    ];
    let i = 0;
    while (i < token_list_key.length) {
      keys.push({
        pubkey: new PublicKey(token_list_key[i]),
        isSigner: false,
        isWritable: false,
      });
      keys.push({
        pubkey: new PublicKey(token_vault_key[i]),
        isSigner: false,
        isWritable: true,
      });
      keys.push({
        pubkey: new PublicKey(oracle_key[i]),
        isSigner: false,
        isWritable: false,
      });
      i++;
    }

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static createSYBuyInstruction(
    sap_owner_key,
    token_program_key,
    sap_pool_acc,
    sap_pool_l1_mint_acc,
    sap_pool_l2_mint_acc,
    user_sap_acc,
    user_wallet_acc,
    user_token_acc,
    clock_acc,
    token_list_key,
    token_vault_key,
    oracle_key,
    token_pubkey,
    amount_in,
    ltype,
    mint_fee,
    programId
  ) {
    console.log(
      "sy mint",
      "sap owner",
      sap_owner_key.toBase58(),
      "token program",
      token_program_key.toBase58(),
      "sap pool",
      sap_pool_acc.toBase58(),
      "sap pool mint l1",
      sap_pool_l1_mint_acc.toBase58(),
      "sap pool mint l2",
      sap_pool_l2_mint_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user wallet",
      user_wallet_acc.toBase58(),
      "user token",
      user_token_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "token pubkey",
      token_pubkey.toBase58(),
      "amount",
      amount_in,
      "ltype",
      ltype,
      "mint fee",
      mint_fee,
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(32, "token_pubkey"),
      BufferLayout.blob(8, "amount_in"),
      BufferLayout.blob(8, "ltype"),
      BufferLayout.blob(8, "fee"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 1,
        token_pubkey: Buffer.from(token_pubkey.toBuffer()),
        amount_in: Buffer.from(new BN(amount_in).toArray("le", 8)),
        ltype: Buffer.from(new BN(ltype).toArray("le", 8)),
        fee: Buffer.from(new BN(mint_fee).toArray("le", 8)),
      },
      data
    );

    let keys = [
      { pubkey: sap_owner_key, isSigner: true, isWritable: false },
      { pubkey: token_program_key, isSigner: false, isWritable: false },
      { pubkey: sap_pool_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_l1_mint_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_l2_mint_acc, isSigner: false, isWritable: true },
      { pubkey: user_sap_acc, isSigner: false, isWritable: true },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_token_acc, isSigner: false, isWritable: true },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
      { pubkey: chainlink_program_acc, isSigner: false, isWritable: false },
    ];
    let i = 0;
    while (i < token_list_key.length) {
      keys.push({
        pubkey: new PublicKey(token_list_key[i]),
        isSigner: false,
        isWritable: false,
      });
      keys.push({
        pubkey: new PublicKey(token_vault_key[i]),
        isSigner: false,
        isWritable: true,
      });
      keys.push({
        pubkey: new PublicKey(oracle_key[i]),
        isSigner: false,
        isWritable: false,
      });
      i++;
    }

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static createSYRedeemInstruction(
    token_program_key,
    sap_pool_acc,
    sap_pool_l1_mint_acc,
    sap_pool_l2_mint_acc,
    user_sap_acc,
    user_wallet_acc,
    user_token_acc,
    manager_acc,
    clock_acc,
    token_list_key,
    token_vault_key,
    oracle_key,
    amount_in,
    ltype,
    burn_fee,
    sap_cost,
    performance_fee,
    programId
  ) {
    console.log(
      "sy redeem",
      "token program",
      token_program_key.toBase58(),
      "sap pool",
      sap_pool_acc.toBase58(),
      "sap pool mint l1",
      sap_pool_l1_mint_acc.toBase58(),
      "sap pool mint l2",
      sap_pool_l2_mint_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user wallet",
      user_wallet_acc.toBase58(),
      "user token",
      user_token_acc.toBase58(),
      "manager",
      manager_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "amount",
      amount_in,
      "ltype",
      ltype,
      "burn fee",
      burn_fee,
      "sap cost",
      sap_cost,
      "performance fee",
      performance_fee,
      "program id",
      programId.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(8, "amount_in"),
      BufferLayout.blob(8, "ltype"),
      BufferLayout.blob(8, "burn_fee"),
      BufferLayout.blob(8, "sap_cost"),
      BufferLayout.blob(8, "performance_fee"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 2,
        amount_in: Buffer.from(new BN(amount_in).toArray("le", 8)),
        ltype: Buffer.from(new BN(ltype).toArray("le", 8)),
        burn_fee: Buffer.from(new BN(burn_fee).toArray("le", 8)),
        sap_cost: Buffer.from(new BN(sap_cost).toArray("le", 8)),
        performance_fee: Buffer.from(new BN(performance_fee).toArray("le", 8)),
      },
      data
    );

    let keys = [
      { pubkey: token_program_key, isSigner: false, isWritable: false },
      { pubkey: sap_pool_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_l1_mint_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_l2_mint_acc, isSigner: false, isWritable: true },
      { pubkey: user_sap_acc, isSigner: false, isWritable: true },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_token_acc, isSigner: false, isWritable: true },
      { pubkey: manager_acc, isSigner: true, isWritable: false },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
      { pubkey: chainlink_program_acc, isSigner: false, isWritable: false },
    ];
    let i = 0;
    while (i < token_list_key.length) {
      keys.push({
        pubkey: new PublicKey(token_list_key[i]),
        isSigner: false,
        isWritable: false,
      });
      keys.push({
        pubkey: new PublicKey(token_vault_key[i]),
        isSigner: false,
        isWritable: true,
      });
      keys.push({
        pubkey: new PublicKey(oracle_key[i]),
        isSigner: false,
        isWritable: false,
      });
      i++;
    }

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  }

  static createSYMember = (
    sap_pool_acc,
    user_sap_member_acc,
    user_wallet_acc,
    user_sap_acc,
    user_reward_spt_acc,
    token_program_acc,
    nonce,
    ltype,
    programId
  ) => {
    console.log(
      "create sy member",
      "sap pool",
      sap_pool_acc.toBase58(),
      "user sap member",
      user_sap_member_acc.toBase58(),
      "user wallet",
      user_wallet_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user reward spt",
      user_reward_spt_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "nonce",
      nonce,
      "ltype",
      ltype,
      "program id",
      programId.toBase58()
    );

    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(8, "nonce"),
      BufferLayout.blob(8, "ltype"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 7,
        nonce: Buffer.from(new BN(nonce).toArray("le", 8)),
        ltype: Buffer.from(new BN(ltype).toArray("le", 8)),
      },
      data
    );

    let keys = [
      { pubkey: sap_pool_acc, isSigner: false, isWritable: false },
      { pubkey: user_sap_member_acc, isSigner: false, isWritable: true },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_sap_acc, isSigner: false, isWritable: false },
      { pubkey: user_reward_spt_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  };

  static createSYPreBuyInstruction = (
    amount,
    fee,
    sap_pool_acc,
    sap_pre_mint_acc,
    sap_pool_vault_acc,
    oracle_price_acc,
    user_wallet_acc,
    user_token_acc,
    user_sap_acc,
    user_token_mint_acc,
    owner_acc,
    token_program_acc,
    programId
  ) => {
    console.log(
      "sy pre buy",
      "amount",
      amount,
      "fee",
      fee,
      "sap pool",
      sap_pool_acc.toBase58(),
      "pre mint",
      sap_pre_mint_acc.toBase58(),
      "sap vault",
      sap_pool_vault_acc.toBase58(),
      "oracle",
      oracle_price_acc.toBase58(),
      "user wallet",
      user_wallet_acc.toBase58(),
      "user token",
      user_token_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user token mint",
      user_token_mint_acc.toBase58(),
      "owner",
      owner_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programId.toBase58()
    );

    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.nu64("amount"),
      BufferLayout.nu64("fee"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 11,
        amount,
        fee,
      },
      data
    );

    let keys = [
      { pubkey: sap_pool_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pre_mint_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_vault_acc, isSigner: false, isWritable: true },
      { pubkey: oracle_price_acc, isSigner: false, isWritable: false },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_token_acc, isSigner: false, isWritable: true },
      { pubkey: user_sap_acc, isSigner: false, isWritable: true },
      { pubkey: user_token_mint_acc, isSigner: false, isWritable: false },
      { pubkey: owner_acc, isSigner: true, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
      { pubkey: chainlink_program_acc, isSigner: false, isWritable: false },
    ];

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  };

  static createSYPreSellInstruction = (
    amount,
    fee,
    sap_pool_acc,
    sap_pre_mint_acc,
    sap_pool_vault_acc,
    oracle_price_acc,
    user_wallet_acc,
    user_token_acc,
    user_sap_acc,
    user_token_mint_acc,
    manager_acc,
    token_program_acc,
    programId
  ) => {
    console.log(
      "sy pre sell",
      "amount",
      amount,
      "fee",
      fee,
      "sap pool",
      sap_pool_acc.toBase58(),
      "pre mint",
      sap_pre_mint_acc.toBase58(),
      "sap vault",
      sap_pool_vault_acc.toBase58(),
      "oracle",
      oracle_price_acc.toBase58(),
      "user wallet",
      user_wallet_acc.toBase58(),
      "user token",
      user_token_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user token mint",
      user_token_mint_acc.toBase58(),
      "manager",
      manager_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programId.toBase58()
    );

    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.nu64("amount"),
      BufferLayout.nu64("fee"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 12,
        amount,
        fee,
      },
      data
    );

    let keys = [
      { pubkey: sap_pool_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pre_mint_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_vault_acc, isSigner: false, isWritable: true },
      { pubkey: oracle_price_acc, isSigner: false, isWritable: false },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_token_acc, isSigner: false, isWritable: true },
      { pubkey: user_sap_acc, isSigner: false, isWritable: true },
      { pubkey: user_token_mint_acc, isSigner: false, isWritable: false },
      { pubkey: manager_acc, isSigner: true, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
      { pubkey: chainlink_program_acc, isSigner: false, isWritable: false },
    ];

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  };

  static createSYClaimSapInstruction = (
    sap_pool_acc,
    sap_pool_mint_acc,
    sap_pool_vault_acc,
    sap_pre_mint_acc,
    user_wallet_acc,
    user_sap_acc,
    user_pre_sap_acc,
    owner_acc,
    manager_acc,
    user_token_acc,
    user_token_mint_acc,
    token_program_acc,
    programId
  ) => {
    console.log(
      "sy claim sap",
      "sap pool",
      sap_pool_acc.toBase58(),
      "sap mint",
      sap_pool_mint_acc.toBase58(),
      "sap vault",
      sap_pool_vault_acc.toBase58(),
      "pre mint",
      sap_pre_mint_acc.toBase58(),
      "user wallet",
      user_wallet_acc.toBase58(),
      "user sap",
      user_sap_acc.toBase58(),
      "user pre sap",
      user_pre_sap_acc.toBase58(),
      "owner",
      owner_acc.toBase58(),
      "manager",
      manager_acc.toBase58(),
      "user token",
      user_token_acc.toBase58(),
      "user token mint",
      user_token_mint_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programId.toBase58()
    );

    const dataLayout = BufferLayout.struct([BufferLayout.u8("i")]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        i: 9,
      },
      data
    );

    let keys = [
      { pubkey: sap_pool_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pool_vault_acc, isSigner: false, isWritable: true },
      { pubkey: sap_pre_mint_acc, isSigner: false, isWritable: true },
      { pubkey: user_wallet_acc, isSigner: true, isWritable: false },
      { pubkey: user_sap_acc, isSigner: false, isWritable: true },
      { pubkey: user_pre_sap_acc, isSigner: false, isWritable: true },
      { pubkey: owner_acc, isSigner: true, isWritable: false },
      { pubkey: manager_acc, isSigner: true, isWritable: false },
      { pubkey: user_token_acc, isSigner: false, isWritable: true },
      { pubkey: user_token_mint_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];

    const trxi = new TransactionInstruction({ keys, programId, data });
    return trxi;
  };

  static createTransferInstruction = (
    programId,
    source,
    destination,
    owner,
    multiSigners,
    amount
  ) => {
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("instruction"),
      BufferLayout.blob(64, "amount"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      { instruction: 3, amount: Buffer.from(new BN(amount).toArray("le", 64)) },
      data
    );

    let keys = [
      { pubkey: source, isSigner: false, isWritable: true },
      { pubkey: destination, isSigner: false, isWritable: true },
    ];
    if (multiSigners.length === 0) {
      keys.push({ pubkey: owner, isSigner: true, isWritable: false });
    } else {
      keys.push({ pubkey: owner, isSigner: false, isWritable: false });
      multiSigners.forEach((signer) =>
        keys.push({
          pubkey: signer.publicKey,
          isSigner: true,
          isWritable: false,
        })
      );
    }
    return new TransactionInstruction({ keys, programId, data });
  };
}
