import { sendTransaction } from "./sendTransaction";
import { sleep } from "./timeout";

const {
  PublicKey,
  SystemProgram,
  Transaction,
  Keypair,
} = require("@solana/web3.js");
const { TOKEN_PROGRAM_ID, Token, AccountLayout } = require("@solana/spl-token");

export async function getBalance(connection, publicKey, mint) {
  var balanceList = [];
  var totalBalance = 0;
  let acc = await connection.getTokenAccountsByOwner(publicKey, { mint });
  if (acc.value.length > 0) {
    for (let i = 0; i < acc.value.length; i++) {
      let res = await connection.getTokenAccountBalance(acc.value[i].pubkey);
      if (res != undefined) {
        let temp = {
          acc: acc.value[i].pubkey,
          amount: parseFloat(res.value.uiAmount),
          decimal: parseFloat(res.value.decimals),
        };
        balanceList.push(temp);
        totalBalance += temp.amount;
      }
    }
  }
  return { balanceList, totalBalance };
}

/**
 * @param {import("@solana/web3.js").Connection} connection connection
 * @param {import("@solana/web3.js").PublicKey} publicKey account public key
 * @param {import("@solana/web3.js").PublicKey} mint mint public key
 * @returns {Promise<{acc:import("@solana/web3.js").PublicKey|null;amount:number;decimal:number}>}
 */
export async function getBalanceSingle(connection, publicKey, mint) {
  let acc = await connection.getTokenAccountsByOwner(publicKey, { mint });
  if (acc.value.length > 0) {
    let res = await connection.getTokenAccountBalance(acc.value[0].pubkey);
    if (res != undefined) {
      let balance = {
        acc: acc.value[0].pubkey,
        amount: parseFloat(res.value.uiAmount),
        decimal: parseFloat(res.value.decimals),
      };
      return balance;
    }
  } else {
    return { acc: null, amount: 0.0, decimal: 0 };
  }
}

/**
 * @param {import("@solana/web3.js").Connection} connection
 * @param {import("@solana/web3.js").PublicKey} publicKey
 * @param {import("@solana/web3.js").PublicKey} mint
 * @returns
 */
export async function getTokenAccounts(connection, publicKey, mint) {
  let acc = await connection.getTokenAccountsByOwner(publicKey, { mint });
  if (acc.value.length > 0) {
    return acc.value[0].pubkey;
  }
}

export async function createTokenAccount(connection, wallet, mintKey) {
  let balanceNeeded = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  let walletAcc = wallet.publicKey;
  let newAccount = Keypair.generate();
  let mintAcc = new PublicKey(mintKey);
  let transaction = new Transaction();
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccount.publicKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      mintAcc,
      newAccount.publicKey,
      walletAcc
    )
  );
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    transaction = await wallet.signTransaction(transaction);
    try {
      transaction.partialSign(newAccount);
    } catch (e) {
      console.error("create token account sign error", e);
      return { code: -2, msg: "partial sign error" };
    }
  } catch (e) {
    console.error("create token account sign cancelled", e);
    return { code: -1, msg: "sign cancelled" };
  }
  let res = await sendTransaction(connection, transaction.serialize());
  return res;
}

export async function getSignatureStatus(connection, signatrue) {
  if (!signatrue) return;
  let temp = { value: null };
  while (!temp.value) {
    await sleep(3000);
    temp = await connection.getSignatureStatus(signatrue);
  }
  return temp.value;
}

export async function getTokenAccountMaxAmount(connection, wallet, mintKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let mintAcc = new PublicKey(mintKey);
  // find token accounts
  let res = await connection.getTokenAccountsByOwner(walletAcc, {
    mint: mintAcc,
  });
  if (res.value.length == 1) {
    // user has only one token aacount
    let res1 = await connection.getTokenAccountBalance(res.value[0].pubkey);
    if (res1 != undefined) {
      let balance = {
        acc: res.value[0].pubkey,
        amount: parseFloat(res1.value.uiAmount),
        decimal: parseFloat(res1.value.decimals),
      };
      return { code: 1, msg: "user has only one token account", data: balance };
    }
  } else if (res.value.length == 0) {
    // user has no token account
    return { code: -1, msg: "user has no token account", data: null };
  } else {
    // get token account amount
    let accounts = [];
    for (let i = 0; i < res.value.length; i++) {
      let tempAcc = res.value[i].pubkey;
      let res1 = await connection.getTokenAccountBalance(tempAcc);
      if (res1) {
        let balance = {
          acc: res.value[i].pubkey,
          amount: parseFloat(res1.value.uiAmount),
          decimal: parseFloat(res1.value.decimals),
        };
        accounts.push(balance);
      }
    }
    // find which token account has max amount
    if (accounts.length > 0) {
      let amounts = accounts.map((e) => {
        return e.amount;
      });
      let maxAmount = Math.max(...amounts);
      let maxIndex = amounts.indexOf(maxAmount);
      if (maxIndex != -1) {
        return {
          code: 1,
          msg: "find token account ok",
          data: accounts[maxIndex],
        };
      } else {
        return {
          code: 0,
          msg: "can not find amount max token account",
          data: null,
        };
      }
    } else {
      return {
        code: 0,
        msg: "can not find amount max token account",
        data: null,
      };
    }
  }
}
