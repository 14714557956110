export function readLittleInt64(data) {
  let buffer = new ArrayBuffer(8);
  let dataView = new DataView(buffer);
  for (let i = 0; i < 8; i++) {
    dataView.setInt8(i, data[i]);
  }
  let view = parseInt(dataView.getBigInt64(0, true));
  return view;
}

export function readLittleInt32(data) {
  let buffer = new ArrayBuffer(4);
  let dataView = new DataView(buffer);
  for (let i = 0; i < 4; i++) {
    dataView.setInt8(i, data[i]);
  }
  let view = parseInt(dataView.getInt32(0, true));
  return view;
}

export function readLittleFloat64(data) {
  let buffer = new ArrayBuffer(8);
  let dataView = new DataView(buffer);
  for (let i = 0; i < 8; i++) {
    dataView.setInt8(i, data[i]);
  }
  let view = parseFloat(dataView.getFloat64(0, true));
  return view;
}

export function readUInt8(data) {
  let buffer = new ArrayBuffer(1);
  let dataView = new DataView(buffer);
  dataView.setInt8(0, data[0]);
  let view = parseInt(dataView.getUint8(0));
  return view;
}
