import "animate.css";
import * as echarts from "echarts";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import wallet from "../plugins/wallet";
import App from "./App.vue";
import Axios from "./plugin/axios";
import router from "./router";
import store from "./store";

Vue.prototype.$axios = Axios;

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(ElementUI, { locale });
// Vue.use(elTableInfiniteScroll);

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.prototype.$walletConnection = wallet.walletConnection;
Vue.prototype.$createMintTokenAccount = wallet.createMintTokenAccount;

Vue.prototype.openLoading = function() {
  const loading = this.$loading({
    // 声明一个loading对象
    lock: true, // 是否锁屏
    text: "transaction confirming", // 加载动画的文字
    spinner: "el-icon-loading", // 引入的loading图标
    background: "rgba(0, 0, 0, 0.3)", // 背景颜色
    target: ".sub-main", // 需要遮罩的区域
    body: true,
    customClass: "mask", // 遮罩层新增类名
  });
  return loading;
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
