import { Transaction } from "@solana/web3.js";
import { PublicKey } from "@solana/web3.js";
import {
  Token,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

const rpcUrl1 =
  "https://mainnet.helius-rpc.com/?api-key=67f8a76b-7b71-4afe-8f3c-8666f2f6b117";
const rpcUrl2 =
  "https://solana--mainnet.datahub.figment.io/apikey/53c6cbe17fac3f71faaa80cdcdc05e05";

export const rpcUrlMain =
  process.env.NODE_ENV === "development" ? rpcUrl1 : rpcUrl1;
export const rpcUrlDevnet = "https://api.devnet.solana.com/";
export const rpcConfig = {
  commitment: "finalized",
  confirmTransactionInitialTimeout: 600 * 1000,
  // httpHeaders: {
  //   "Content-Type": "application/json",
  // },
};

export const rpcUrl = rpcUrlMain;

export async function transfer(
  connection,
  wallet,
  source,
  destination,
  amount
) {
  // use account
  let owner = wallet.publicKey;
  // make transaction
  let trix = Token.createTransferInstruction(
    TOKEN_PROGRAM_ID,
    source,
    destination,
    owner,
    [],
    amount
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = owner;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  // sign
  let signed;
  try {
    signed = await wallet.signTransaction(transaction);
  } catch (error) {
    return { code: -1, msg: "sign cancelled", error };
  }
  // send transaction
  try {
    let signatrue = await connection.sendRawTransaction(signed.serialize());
    return { code: 1, msg: "transfer ok", signatrue };
  } catch (error) {
    return { code: -3, msg: "transfer error", error };
  }
}

export async function createAssociatedTokenAccount(
  connection,
  wallet,
  mintKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  // create account
  let mintAcc = new PublicKey(mintKey);
  // make transction
  let acc = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    mintAcc,
    walletAcc
  );
  let tx = new Transaction().add(
    Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mintAcc,
      acc,
      walletAcc,
      walletAcc
    )
  );
  let res = await signAndSendTransaction(connection, wallet, null, tx);
  if (res.code == 1) {
    return {
      code: 1,
      msg: "token account create ok",
      data: acc.toBase58(),
      signature: res.data,
    };
  } else {
    return res;
  }
}

export async function getMintInfo(connection, mintKey) {
  let mintAcc = new PublicKey(mintKey);
  const payerKey = "AydJEQNvH44P1VA8uSMqfF6MvqMVoeLoxMzt8Wtfrr3B";
  const payerAcc = new PublicKey(payerKey);
  let mint = new Token(connection, mintAcc, TOKEN_PROGRAM_ID, payerAcc);
  let res = await mint.getMintInfo();
  return { code: 1, msg: "get mint info ok", data: res };
}
