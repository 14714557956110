/*
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-08-06 00:10:10
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-08-11 23:41:45
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    walletConnectInfo: "",
    isLogin: true,
    dialogAction: "",
    dialogVisible: false,
    dialogCurFund: "",
  },
  mutations: {
    SET_DIALOG_ACT: (state, payload) => {
      if (payload) {
        state.dialogAction = payload;
      }
      state.dialogVisible = true;
    },
    SET_DIALOG_CLOSE: (state) => {
      state.dialogVisible = false;
    },
    SET_DALOG_CURFUND: (state, payload) => {
      state.dialogCurFund = payload;
    },
  },
  actions: {},
  modules: {},
});
