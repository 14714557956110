/* eslint-disable */

"use strict";
const {
  PublicKey,
  SystemProgram,
  Transaction,
  Keypair,
} = require("@solana/web3.js");
const bs58 = require("bs58");
const { TOKEN_PROGRAM_ID, AccountLayout, Token } = require("@solana/spl-token");
const { timeout, sleep } = require("@/assets/js/timeout");
import {
  getBalance,
  getBalanceSingle,
  getTokenAccountMaxAmount,
  getTokenAccounts,
} from "../getBalance.js";
const { SYPMINT, USDCMINT } = require("../token");
const sypAcc = new PublicKey(SYPMINT);
const usdcAcc = new PublicKey(USDCMINT);
// import { rpcURL } from '../oracleList';
import { readLittleInt64, readUInt8, readLittleFloat64 } from "../readBinary";
import { SapInstruction } from "../sapInstruction";
import * as BufferLayout from "buffer-layout";
import { signAndSendTransaction } from "../sendTransaction.js";

// program id
export const sapProgramId = "HmkKQM8R3BmeqfhhztrvdyGnoeByMDVCnpjwL4ujdgni";
export const syProgramId = "DMVYowvuL1ybWTcWKQwkyqXChm3ptdSpCsZaAfNU8uDm";
const syProgramAcc = new PublicKey(syProgramId);
export const serverOwner = "AydJEQNvH44P1VA8uSMqfF6MvqMVoeLoxMzt8Wtfrr3B";
export const serverManager = "6WUq1TwjnQchBbFxU1RNtzswbFm6swAXdAt4W2DqfnhP";
export const SY_LEN = 2;
export const TOKEN_NUM = 6;
// state size
const SY_MEMBER_SIZE = 32 * 4 + 8 * 4;

export const nullKey = "11111111111111111111111111111111";

// seed
const seedPre = "1228b";
const syMemberSeed = seedPre + "SypoolSYMember";

// data
const sapDataLayout = BufferLayout.struct([
  BufferLayout.nu64("status"),
  BufferLayout.nu64("nonce"),
  BufferLayout.nu64("sypPercent"),
  BufferLayout.blob(32, "ownerKey"),
  BufferLayout.blob(32, "sapMintKey"),
  BufferLayout.blob(32, "managerKey"),
  BufferLayout.blob(32, "sypVault"),
  BufferLayout.blob(32, "ownerKey"),
  BufferLayout.blob(32, "oraclePriceKey0"),
  BufferLayout.blob(32, "oraclePriceKey1"),
  BufferLayout.blob(32, "oraclePriceKey2"),
  BufferLayout.blob(32, "oraclePriceKey3"),
  BufferLayout.blob(32, "oraclePriceKey4"),
  BufferLayout.blob(32, "oraclePriceKey5"),
  BufferLayout.blob(32, "tokenKey0"),
  BufferLayout.blob(32, "tokenKey1"),
  BufferLayout.blob(32, "tokenKey2"),
  BufferLayout.blob(32, "tokenKey3"),
  BufferLayout.blob(32, "tokenKey4"),
  BufferLayout.blob(32, "tokenKey5"),
  BufferLayout.blob(32, "vaultKey0"),
  BufferLayout.blob(32, "vaultKey1"),
  BufferLayout.blob(32, "vaultKey2"),
  BufferLayout.blob(32, "vaultKey3"),
  BufferLayout.blob(32, "vaultKey4"),
  BufferLayout.blob(32, "vaultKey5"),
  BufferLayout.f64("fee"),
  BufferLayout.f64("performanceFee"),
]);
const syDataLayout = BufferLayout.struct([
  // 0 is not init 1 is pre mint 2 is lock 3 is free mint burn
  BufferLayout.nu64("status"),
  BufferLayout.nu64("nonce"),
  BufferLayout.nu64("sypPercent"),
  BufferLayout.blob(32, "ownerKey"),
  BufferLayout.blob(32, "sapMintL1Key"),
  BufferLayout.blob(32, "sapMintL2Key"),
  BufferLayout.blob(32, "sapMintL3Key"),
  BufferLayout.blob(32, "preMintL1Key"),
  BufferLayout.blob(32, "preMintL2Key"),
  BufferLayout.blob(32, "managerKey"),
  BufferLayout.blob(32, "sypVault"),
  BufferLayout.blob(32, "oraclePriceKey0"),
  BufferLayout.blob(32, "oraclePriceKey1"),
  BufferLayout.blob(32, "oraclePriceKey2"),
  BufferLayout.blob(32, "oraclePriceKey3"),
  BufferLayout.blob(32, "oraclePriceKey4"),
  BufferLayout.blob(32, "oraclePriceKey5"),
  BufferLayout.blob(32, "tokenKey0"),
  BufferLayout.blob(32, "tokenKey1"),
  BufferLayout.blob(32, "tokenKey2"),
  BufferLayout.blob(32, "tokenKey3"),
  BufferLayout.blob(32, "tokenKey4"),
  BufferLayout.blob(32, "tokenKey5"),
  BufferLayout.blob(32, "vaultKey0"),
  BufferLayout.blob(32, "vaultKey1"),
  BufferLayout.blob(32, "vaultKey2"),
  BufferLayout.blob(32, "vaultKey3"),
  BufferLayout.blob(32, "vaultKey4"),
  BufferLayout.blob(32, "vaultKey5"),
  BufferLayout.f64("initPrice"),
  BufferLayout.ns64("initTime"),
  BufferLayout.ns64("timeLock"),
  BufferLayout.nu64("preMintL1Amount"),
  BufferLayout.nu64("preMintL2Amount"),
  BufferLayout.f64("preMintL1ClaimRate"),
  BufferLayout.f64("preMintL2ClaimRate"),
  BufferLayout.f64("fee"),
  BufferLayout.f64("performanceFee"),
]);

export async function getBalanceSyp(connection, wallet) {
  // use account
  let walletAcc = wallet.publicKey;
  let balance = await getBalanceSingle(connection, walletAcc, sypAcc);
  return balance;
}

export async function getBalanceUsdc(connection, wallet) {
  // use account
  let walletAcc = wallet.publicKey;
  let balance = await getBalanceSingle(connection, walletAcc, usdcAcc);
  return balance;
}

export async function getTransactionList(
  connection,
  accountKey,
  lastSignature
) {
  const acc = new PublicKey(accountKey);
  const option = { limit: 30 };
  if (lastSignature) {
    option["before"] = lastSignature;
  }
  let signatureList = await connection.getConfirmedSignaturesForAddress2(
    acc,
    option
  );
  let transactionList = [];
  if (signatureList.length > 0) {
    for (let i = 0; i < signatureList.length; i++) {
      let transaction = await getConfirmedTransaction(
        connection,
        signatureList[i].signature,
        accountKey
      );
      if (transaction) {
        transactionList.push(transaction);
      }
    }
  }
  return transactionList;
}

export async function getConfirmedTransaction(connection, signature, mintKey) {
  let transaction = await connection.getConfirmedTransaction(signature);
  if (transaction) {
    let meta = transaction.meta;
    let date = new Date(transaction.blockTime * 1000);
    let data = transaction.transaction.instructions[0].data;
    let keys = transaction.transaction.instructions[0].keys;
    let transactionProgramId =
      transaction.transaction.instructions[0].programId.toBase58();
    let transactionData = {};
    let userWalletKey = transaction.transaction.feePayer.toBase58();
    if (transactionProgramId === sapProgramId) {
      transactionData = getSapTransactionData(
        data,
        keys,
        meta,
        userWalletKey,
        mintKey
      );
    } else if (transactionProgramId === syProgramId) {
      transactionData = getSyTransactionData(data, keys, meta);
    } else {
      transactionData = { action: -1 };
    }
    transactionData["programId"] = transactionProgramId;
    transactionData["date"] = date;
    transactionData["signature"] = signature;
    transactionData["feePayer"] = userWalletKey;
    return transactionData;
  }
}

function getSapTransactionData(data, keys, meta, userWalletKey, mintKey) {
  const pubLen = 32;
  let action = readUInt8(data.slice(0, 1)); // 1 buy 2 redeem
  let token = bs58.encode(data.slice(1, 1 + pubLen), "hex");
  // const amount = readLittleInt64(data.slice(1 + pubLen, 1 + pubLen + 8));
  let pubkey = keys[2].pubkey.toBase58();
  let mint = keys[3].pubkey.toBase58();
  let actionStr = "";
  if (action == 1) {
    actionStr = "MINT";
  } else if (action == 2) {
    actionStr = "BURN";
  } else {
    actionStr = "OTHER";
  }
  let amount = 0;
  let price = 0;
  let preAmount = 0;
  let postAmount = 0;
  if (action == 1 || action == 2) {
    let { preSapBalance, postSapBalance, preTokenBalance, postTokenBalance } =
      getTransactionBalances(meta, userWalletKey, mintKey);
    let preSapAmount = preSapBalance.uiTokenAmount.uiAmount || 0;
    let postSapAmount = postSapBalance.uiTokenAmount.uiAmount || 0;
    let preTokenAmount = preTokenBalance.uiTokenAmount.uiAmount || 0;
    let postTokenAmount = postTokenBalance.uiTokenAmount.uiAmount || 0;
    let changeSapAmount = postSapAmount - preSapAmount;
    let changeAmount = preTokenAmount - postTokenAmount;
    amount = Math.abs(changeSapAmount);
    price = changeSapAmount !== 0 ? changeAmount / changeSapAmount : 0.0;
    preAmount = preSapAmount;
    postAmount = postSapAmount;
  }
  return {
    action,
    actionStr,
    token,
    amount,
    preAmount,
    postAmount,
    price,
    pubkey,
    mint,
  };
}

function getSyTransactionData(data, keys, meta, userWalletKey, mintKey) {
  const pubLen = 32;
  let action = readUInt8(data.slice(0, 1)); // 1 buy 2 redeem
  let token = "";
  let pubkey = "";
  let mint = "";
  let ltype = 0;
  let actionStr = "";
  if (action == 1) {
    actionStr = "MINT";
    amount = readLittleInt64(data.slice(1 + pubLen, 1 + pubLen + 8));
    ltype = readLittleInt64(data.slice(1 + pubLen + 8, 1 + pubLen + 8 + 8));
    token = keys[7].pubkey.toBase58();
    pubkey = keys[2].pubkey.toBase58();
    if (ltype == 1) {
      mint = keys[3].pubkey.toBase58();
    } else if (ltype == 2) {
      mint = keys[4].pubkey.toBase58();
    }
  } else if (action == 2) {
    actionStr = "BURN";
    amount = readLittleInt64(data.slice(1, 1 + 8));
    ltype = readLittleInt64(data.slice(1 + 8, 1 + 8 + 8));
    token = keys[7].pubkey.toBase58();
    pubkey = keys[1].pubkey.toBase58();
    if (ltype == 1) {
      mint = keys[2].pubkey.toBase58();
    } else if (ltype == 2) {
      mint = keys[3].pubkey.toBase58();
    }
  } else {
    actionStr = "OTHER";
  }
  let amount = 0;
  let price = 0;
  let preAmount = 0;
  let postAmount = 0;
  if (action == 1 || action == 2) {
    let { preSapBalance, postSapBalance, preTokenBalance, postTokenBalance } =
      getTransactionBalances(meta, userWalletKey, mintKey);
    let preSapAmount = preSapBalance.uiTokenAmount.uiAmount || 0;
    let postSapAmount = postSapBalance.uiTokenAmount.uiAmount || 0;
    let preTokenAmount = preTokenBalance.uiTokenAmount.uiAmount || 0;
    let postTokenAmount = postTokenBalance.uiTokenAmount.uiAmount || 0;
    let changeSapAmount = postSapAmount - preSapAmount;
    let changeAmount = preTokenAmount - postTokenAmount;
    amount = Math.abs(changeSapAmount);
    price = changeSapAmount !== 0 ? changeAmount / changeSapAmount : 0.0;
    preAmount = preSapAmount;
    postAmount = postSapAmount;
  }
  return {
    action,
    actionStr,
    token,
    amount,
    preAmount,
    postAmount,
    price,
    pubkey,
    mint,
    ltype,
  };
}

function getTransactionBalances(meta, userWalletKey, mintKey) {
  let preBalances = meta.preTokenBalances.filter((e) => {
    return e.owner === userWalletKey;
  });
  let preSapBalance;
  let preTokenBalance;
  if (preBalances[0].mint === mintKey) {
    preSapBalance = preBalances[0];
    preTokenBalance = preBalances[1];
  } else {
    preSapBalance = preBalances[1];
    preTokenBalance = preBalances[0];
  }
  let postSapBalance = meta.postTokenBalances.find((e) => {
    return e.accountIndex === preSapBalance.accountIndex;
  });
  let postTokenBalance = meta.postTokenBalances.find((e) => {
    return e.accountIndex === preTokenBalance.accountIndex;
  });
  return {
    preSapBalance,
    postSapBalance,
    preTokenBalance,
    postTokenBalance,
  };
}

export async function getLastSapMintTransaction(connection, userSapAcc) {
  const option = { limit: 30 };
  let signatureList = await connection.getConfirmedSignaturesForAddress2(
    userSapAcc,
    option
  );
  let transaction;
  let findOK = 0;
  if (signatureList.length > 0) {
    for (let i = 0; i < signatureList.length; i++) {
      transaction = await getConfirmedTransaction(
        connection,
        signatureList[i].signature
      );
      if (transaction.programId == sapProgramId && transaction.action == 1) {
        findOK = 1;
        break;
      }
    }
  }
  if (findOK == 1) {
    return transaction;
  } else {
    return null;
  }
}

export async function getSYMemberKey(wallet) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberAcc = await PublicKey.createWithSeed(
    walletAcc,
    syMemberSeed,
    syProgramAcc
  );
  return memberAcc.toBase58();
}

export async function createSYMemberAcc(connection, wallet) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberKey = await getSYMemberKey(wallet);
  let memberAcc = new PublicKey(memberKey);
  // create member account
  const balanceNeeded = await connection.getMinimumBalanceForRentExemption(
    SY_MEMBER_SIZE
  );
  const transaction = new Transaction().add(
    SystemProgram.createAccountWithSeed({
      fromPubkey: walletAcc,
      basePubkey: walletAcc,
      seed: syMemberSeed,
      newAccountPubkey: memberAcc,
      lamports: balanceNeeded,
      space: SY_MEMBER_SIZE,
      programId: syProgramAcc,
    })
  );
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      console.log("create member signature", signature);
      var memberData;
      let start = new Date();
      while (memberData == null) {
        await sleep(3000);
        memberData = await getSYMemberData(connection, memberKey);
        if (timeout(start, 180000)) {
          console.log("wait sy member acc time out");
          return { code: -2, msg: "time out" };
        }
      }
      return { code: 1, msg: "create sy member acc ok", data: memberData };
    } catch (e) {
      return { code: -3, msg: "create sy memeber account error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

// get member data
export async function getSYMemberData(connection, memberKey) {
  let memberAcc = new PublicKey(memberKey);
  let memberData = await connection.getAccountInfo(memberAcc);
  if (memberData != null) {
    return getSYMemberDataRaw(memberData);
  } else {
    // console.error('member data is null');
    return;
  }
}

// get member data from binary data
export function getSYMemberDataRaw(memberData) {
  let accData = memberData.data;
  let pubLen = 32;
  let sapPool = bs58.encode(accData.slice(0, pubLen), "hex");
  let userWallet = bs58.encode(accData.slice(pubLen, pubLen * 2), "hex");
  let userSap = bs58.encode(accData.slice(pubLen * 2, pubLen * 3), "hex");
  let userRewardSpt = bs58.encode(accData.slice(pubLen * 3, pubLen * 4), "hex");
  let nonce = readLittleInt64(
    accData.slice(pubLen * 4 + 8, pubLen * 7 + 8 * 2)
  );
  let ltype = readLittleInt64(
    accData.slice(pubLen * 4 + 8 * 2, pubLen * 7 + 8 * 3)
  );
  let sapCost = readLittleInt64(
    accData.slice(pubLen * 4 + 8 * 3, pubLen * 7 + 8 * 4)
  );
  let lastMintTs = readLittleInt64(
    accData.slice(pubLen * 4 + 8 * 4, pubLen * 7 + 8 * 5)
  );
  return {
    sapPool,
    userWallet,
    userSap,
    userRewardSpt,
    nonce,
    ltype,
    sapCost,
    lastMintTs,
  };
}

export async function createSYMember(
  connection,
  wallet,
  sapPoolKey,
  syMemberKey,
  userSapKey,
  nonce,
  ltype
) {
  // use account
  let sapPoolAcc = new PublicKey(sapPoolKey);
  let syMemberAcc = new PublicKey(syMemberKey);
  let walletAcc = wallet.publicKey;
  let userSapAcc = new PublicKey(userSapKey);
  // make instruction
  let trix = SapInstruction.createSYMember(
    sapPoolAcc,
    syMemberAcc,
    walletAcc,
    userSapAcc,
    userSapAcc,
    TOKEN_PROGRAM_ID,
    nonce,
    ltype,
    syProgramAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send trasaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      let start = new Date();
      var code = 0;
      var memberData;
      while (code != 1) {
        await sleep(3000);
        memberData = await getSYMemberData(connection, syMemberKey);
        if (memberData.userWallet != nullKey) {
          code = 1;
        }
        if (timeout(start, 180000)) {
          return { code: -2, msg: "time out", data: signature };
        }
      }
      console.log("create sy member ok", memberData);
      return { code: 1, msg: "create sy member  ok", data: memberData };
    } catch (e) {
      return { code: -3, msg: "create sy memeber error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function checkSyLimit(connection, mintKey, limit) {
  let supply = await connection.getTokenSupply(new PublicKey(mintKey));
  if (supply.value) {
    return limit >= supply.value.uiAmount;
  } else {
    return false;
  }
}

export async function getSapData(connection, sapKey) {
  // use account
  let sapAcc = new PublicKey(sapKey);
  // get data
  let sapData = await connection.getAccountInfo(sapAcc);
  if (sapData) {
    // console.log('sap data length', sapData.data.length, sapDataLayout.span);
    if (sapData.data.length >= sapDataLayout.span) {
      let temp = sapDataLayout.decode(sapData.data);
      temp["sapKey"] = sapKey;
      return { code: 1, msg: "get sap data ok", data: handleKey(temp) };
    } else {
      return { code: 0, msg: "get sap data fail", data: null };
    }
  } else {
    return { code: 0, msg: "sap is null", data: null };
  }
}

export async function getSyData(connection, sapKey) {
  // use account
  let sapAcc = new PublicKey(sapKey);
  // get data
  let sapData = await connection.getAccountInfo(sapAcc);
  if (sapData) {
    // console.log('sy data length', sapData.data.length, syDataLayout.span);
    if (sapData.data.length >= syDataLayout.span) {
      let temp = syDataLayout.decode(sapData.data);
      temp["sapKey"] = sapKey;
      temp["limit"] = [temp.preMintL1Amount, temp.preMintL2Amount];
      return { code: 1, msg: "get sap data ok", data: handleKey(temp) };
    } else {
      return { code: 0, msg: "get sap data fail", data: null };
    }
  } else {
    return { code: 0, msg: "sap is null", data: null };
  }
}

function handleKey(data) {
  for (let key in data) {
    if (data[key].length == 32) {
      let pubkey = new PublicKey(data[key]);
      data[key] = pubkey.toBase58();
    }
  }
  return data;
}

export async function preMintSy(
  connection,
  wallet,
  sapKey,
  sapPreMintKey,
  amount,
  fee
) {
  // use account
  let walletAcc = wallet.publicKey;
  let sapAcc = new PublicKey(sapKey);
  let sapPreMintAcc = new PublicKey(sapPreMintKey);
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, USDCMINT);
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return { code: 0, msg: "user has no usdc account" };
    }
  }
  let userSapAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, sapPreMintKey);
    if (res.code == 1) {
      userSapAcc = res.data.acc;
    } else {
      // create account
      let res = await createTokenAccount(
        connection,
        wallet,
        walletAcc.toBase58(),
        sapPreMintKey
      );
      if (res.code != 1) {
        return res;
      }
      userSapAcc = new PublicKey(res.data);
    }
  }
  // use data
  let sapData;
  {
    let res = await getSyData(connection, sapKey);
    if (res.code == 1) {
      sapData = res.data;
    } else {
      return res;
    }
  }
  // make instruction
  let transaction = new Transaction();
  transaction.add(
    SapInstruction.createSYPreBuyInstruction(
      amount,
      fee,
      sapAcc,
      sapPreMintAcc,
      new PublicKey(sapData.vaultKey0),
      new PublicKey(sapData.oraclePriceKey0),
      walletAcc,
      userTokenAcc,
      userSapAcc,
      usdcAcc,
      new PublicKey(serverOwner),
      TOKEN_PROGRAM_ID,
      syProgramAcc
    )
  );
  // sign
  let res = await signAndMakeServerData(connection, wallet, transaction, null, {
    amount,
    fee,
  });
  return res;
}

export async function preBurnSy(
  connection,
  wallet,
  sapKey,
  sapPreMintKey,
  amount,
  fee
) {
  // use account
  let walletAcc = wallet.publicKey;
  let sapAcc = new PublicKey(sapKey);
  let sapPreMintAcc = new PublicKey(sapPreMintKey);
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, USDCMINT);
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return { code: 0, msg: "user has no usdc account" };
    }
  }
  let userSapAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, sapPreMintKey);
    if (res.code == 1) {
      userSapAcc = res.data.acc;
    } else {
      return { code: 0, msg: "user has no sap account" };
    }
  }
  // use data
  let sapData;
  {
    let res = await getSyData(connection, sapKey);
    if (res.code == 1) {
      sapData = res.data;
    } else {
      return res;
    }
  }
  // make instruction
  let transaction = new Transaction();
  transaction.add(
    SapInstruction.createSYPreSellInstruction(
      amount,
      fee,
      sapAcc,
      sapPreMintAcc,
      new PublicKey(sapData.vaultKey0),
      new PublicKey(sapData.oraclePriceKey0),
      walletAcc,
      userTokenAcc,
      userSapAcc,
      usdcAcc,
      new PublicKey(serverManager),
      TOKEN_PROGRAM_ID,
      syProgramAcc
    )
  );
  // sign
  let res = await signAndMakeServerData(connection, wallet, transaction, null, {
    amount,
    fee,
  });
  return res;
}

export async function preClaimSy(
  connection,
  wallet,
  sapKey,
  sapPreMintKey,
  sapMintKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  let sapAcc = new PublicKey(sapKey);
  let sapPreMintAcc = new PublicKey(sapPreMintKey);
  let sapMintAcc = new PublicKey(sapMintKey);
  let userTokenAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, USDCMINT);
    if (res.code == 1) {
      userTokenAcc = res.data.acc;
    } else {
      return { code: 0, msg: "user has no usdc account" };
    }
  }
  let userPreSapAcc;
  let userPreSapAmount = 0;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, sapPreMintKey);
    if (res.code == 1) {
      userPreSapAcc = res.data.acc;
      userPreSapAmount = res.data.amount;
      if (userPreSapAmount == 0) {
        return { code: -3, msg: "pre sap amout zero" };
      }
    } else {
      return { code: 0, msg: "user has no pre sap account" };
    }
  }
  let userSapAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, sapMintKey);
    if (res.code == 1) {
      userSapAcc = res.data.acc;
    } else {
      // create account
      let res = await createTokenAccount(
        connection,
        wallet,
        walletAcc.toBase58(),
        sapMintKey
      );
      if (res.code != 1) {
        return res;
      }
      userSapAcc = new PublicKey(res.data);
    }
  }
  // use data
  let sapData;
  {
    let res = await getSyData(connection, sapKey);
    if (res.code == 1) {
      sapData = res.data;
    } else {
      return res;
    }
  }
  // make instruction
  let transaction = new Transaction();
  transaction.add(
    SapInstruction.createSYClaimSapInstruction(
      sapAcc,
      sapMintAcc,
      new PublicKey(sapData.vaultKey0),
      sapPreMintAcc,
      walletAcc,
      userSapAcc,
      userPreSapAcc,
      new PublicKey(serverOwner),
      new PublicKey(serverManager),
      userTokenAcc,
      usdcAcc,
      TOKEN_PROGRAM_ID,
      syProgramAcc
    )
  );
  // sign
  let res = await signAndMakeServerData(
    connection,
    wallet,
    transaction,
    null,
    {}
  );
  res["preSapAmount"] = userPreSapAmount;
  return res;
}

export async function createTokenAccount(
  connection,
  wallet,
  ownerKey,
  mintKey
) {
  let newAccount = Keypair.generate();
  let lamports = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  let tx = new Transaction();
  tx.add(
    SystemProgram.createAccount({
      fromPubkey: new PublicKey(ownerKey),
      newAccountPubkey: newAccount.publicKey,
      lamports,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      new PublicKey(mintKey),
      newAccount.publicKey,
      new PublicKey(ownerKey)
    )
  );
  let res = await signAndSendTransaction(connection, wallet, [newAccount], tx);
  if (res.code == 1) {
    return {
      code: 1,
      msg: "create token account ok",
      data: newAccount.publicKey.toBase58(),
    };
  } else {
    return res;
  }
}

async function signAndMakeServerData(
  connection,
  wallet,
  transaction,
  signers,
  data
) {
  // use account
  let walletAcc = wallet.publicKey;
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  // sign
  let signed;
  try {
    signed = await wallet.signTransaction(transaction);
    if (signers) {
      signers.forEach((e) => {
        transaction.partialSign(e);
      });
    }
  } catch (error) {
    console.error("create sap account error", error);
    return { code: -1, msg: "sign cancelled" };
  }
  const temp = JSON.parse(JSON.stringify(signed));
  temp.signatures = signed.signatures.map((e) => {
    return {
      publicKey: e.publicKey.toBase58(),
      signature: !e.signature ? null : Array.from(e.signature),
    };
  });
  return {
    code: 1,
    msg: "create init sap instruction ok",
    data: JSON.stringify(temp),
    userWalletKey: walletAcc.toBase58(),
    ...data,
  };
}
