/*
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-08-04 23:26:13
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-08-05 07:40:40
 */
import store from "@/store";
import axios from "axios";
import Router from "@/router/index";
axios.defaults.withCredentials = true;

function errorCreate(msg) {
  const error = new Error(msg);
  errorLog(error);

  throw error;
}

function errorLog(error) {
  // store.dispatch('d2admin/log/push', {
  //   message: 'wrong request',
  //   type: 'danger',
  //   meta: {
  //     error
  //   }
  // })
  if (process.env.NODE_ENV === "development" || !process.env.NODE_ENV) {
    console.log(error);
  }
}

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'http://127.0.0.1:8091',
  timeout: 100000, //
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const dataAxios = response.data;
    const { code } = dataAxios;
    if (code === 200) {
      return response;
    } else {
      switch (code) {
        case 1:
          return response;
        case 0:
          return response;
        case 210: //duplicate requests
          break;
        case 401:
          // MessageBox.alert('', '', {
          // confirmButtonText: '',
          // callback: action => {

          Router.push({ path: "/login" });
      }
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "wrong request";
          break;
        case 401:
          error.message = "unauthorized";
          break;
        case 403:
          error.message = "access denied";
          break;
        case 404:
          error.message = `wrong request url: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "request exceed max time ";
          break;
        case 500:
          error.message = "server internal error";
          break;
        case 501:
          error.message = "server error";
          break;
        case 502:
          error.message = "router error";
          break;
        case 503:
          error.message = "service not available";
          break;
        case 504:
          error.message = "router exceed max time";
          break;
        case 505:
          error.message = "http version is not supported";
          break;
        default:
          break;
      }
    }
    errorLog(error);
    return Promise.reject(error);
  }
);

export default service;
