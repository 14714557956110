export function timeout(startDate, timeout) {
  let start = startDate;
  let now = new Date();
  console.log("time passed", now.getTime() - start.getTime(), "ms");
  if (now - start > timeout) {
    return true;
  } else {
    return false;
  }
}

export function sleep(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
